<template>
  <div>
    <div>
      <b-table
        :items="table.items"
        :fields="$t('clan')=='en'?table.fields:tableAR.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >

      <template #empty>
              <div v-if="isBusy" class="text-center ">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="spinnerClass"
                ></b-spinner>
              </div>
              <p
                v-else

                class="text-center muted mb-0 mt-2"
                style="font-size: 13px"
              >
                {{ $t('There are no records to show') }}
              </p>
            </template>


        <template #cell(actions)="row">
          <div class="w-140px">
            <button @click.prevent="viewQueue(row.item.id)">
              <i class="far fas fa-eye"></i>
              {{$t('view')}}
            </button>
            <button
              @click.prevent="showModal(row.item.id, row.item.name)"
              class="ml-5"
            >
              <i class="fas fa-trash mr-2"></i>{{$t('Delete')}}
            </button>
          </div>
        </template>

        <template #cell(clients)="row">
          <b-badge
            variant="success"
            v-for="client in row.item.clients"
            :key="client.full_name"
            class="mr-1"
          >
            {{ client.name }}
          </b-badge>
          <b-badge variant="warning" v-if="row.item.clients.length === 0">
            Not Assigned
          </b-badge>
        </template>

        <template #cell(drivers)="row">
          <b-badge
            variant="primary"
            v-for="driver in row.item.drivers"
            :key="driver.full_name"
            class="mr-1"
          >
            {{ driver.full_name }}
          </b-badge>
          <b-badge variant="warning" v-if="row.item.drivers.length === 0">
            Not Assigned
          </b-badge>
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

     <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link"  @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title="tempName + ' Deletion'"
      hide-footer
    >
      <p>
        Are you sure that you want to delete {{ tempName }}?, if you agree you
        will loose all information about queue
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteQeue">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "QueuesTable",
  async mounted() {
    this.isBusy = true
    await this.$store.dispatch("queues/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      this.isBusy = false
    });
  },

  computed: {
    ...mapGetters({ table: "queues/table" }),
    ...mapGetters({ tableAR: "queues/tableAR" }),
  },
  data: function () {
    return {
      isBusy:'',
      queue: {
        id: null,
      },
      tempId: null,
      tempName: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:''
    };
  },
  methods: {
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      this.isBusy = true
      await this.$store.dispatch("queues/loadPage", this.currentPage);
      this.isBusy = false
    },
    viewQueue(id) {
      this.$router.push({ name: "ViewQueue", params: { id: id } });
    },
    async deleteQeue() {
      this.queue.id = this.tempId;
      await this.$store.dispatch("queues/delete", this.queue);
      await this.$store.dispatch("queues/loadAll").then((res) => {
        this.totalRows = res.data.total;
      });
      this.hideModal();
    },

    showModal(id, name) {
      this.$refs["my-modal"].show();
      this.tempId = id;
      this.tempName = name;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
</style>